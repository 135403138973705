<template>
  <div class="@container">
    <div class="m-auto hidden w-full max-w-[600px] @md:block">
      <brand-nps-slider />
    </div>

    <app-page-section :title="$t('brand.nps_banner.title', { nps: '9,6' })">
      <app-image
        alt=""
        class="-mb-[90px] rounded-lg object-cover object-[center_25%] lg:-mb-[220px] lg:aspect-[2.4]"
        src="https://res.cloudinary.com/dfdgwwkpd/image/upload/v1712830462/brand/enterprise/2ce22d8170e0378a38bdaaef42bb7d1d_eo68ed.jpg"
      />

      <app-carousel
        class="md:px-4"
        controls-position="sides"
        :item-gap="0"
        :item-width="370"
        :items="items"
      >
        <template #item="{ item, index }">
          <div class="h-full p-2" :class="{ 'pl-4': index === 0 }">
            <app-card class="h-full p-8 text-black" elevated>
              <brand-testimonial class="h-full" :testimonial="item" />
            </app-card>
          </div>
        </template>
      </app-carousel>

      <template v-if="$slots.append" #append>
        <slot name="append" />
      </template>
    </app-page-section>
  </div>
</template>

<script lang="ts" setup>
import { testimonials } from "~/business-areas/brand/testimonials.data";

const items = [
  testimonials.delphineMarsh,
  testimonials.carolineRoullet,
  testimonials.nathalieClement,
  testimonials.dorianaKoeberle,
  testimonials.octaveLapeyronie,
];
</script>
